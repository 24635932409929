.Navbar {
  background-color: #121212;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 5rem;
  color: white;
  /* scroll-snap-align: start; */
  padding: 1em 1em 1em 1em;
  font-family: pirulen;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.NavbarLogo {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 20rem;
  min-width: 10rem;
  margin-top: 1em;
  margin-right: 2em;
  transition: 1s;
}

.NavbarLogo:hover {
  transform: scale(110%);
}

.LogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-logo {
  max-width: 100%;
}

.MobileButton {
  font-size: 2rem;
  transition: 1s;
}

.MobileButton:hover {
  cursor: pointer;
  transform: scale(110%);
}

.NavbarItems {
  display: none;
}

#NavbarItems {
  display: none;
}

.nav-link {
  display: none;
}

#MobileNavLinks {
  display: flex;
  font-size: 1rem;
  margin: 0.25em;
  margin-left: 1em;
  margin-right: 1em;
  padding: 0.4em 0.8em 0.4em 0.8em;
  text-decoration: none;
  color: white;
  font-family: pirulen;
  border: 1px solid white;
  transition: 0.6s;
}
#MobileNavLinks:hover {
  transform: scale(110%);
  margin-left: 1em;
  margin-right: 1em;
  background-color: white;
  color: #121212;
}

.AuthButtons {
  display: none;
}

.AuthButtonContainer {
  display: none;
}

.NavbarContentContainer {
  max-width: 70em;
}

@media only screen and (min-width: 61em) {
  .MobileButton {
    display: none;
  }

  .NavbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-link {
    display: flex;
    font-size: 0.8rem;
    margin-left: 1em;
    margin-right: 1em;
    padding: 0.4em 0.8em 0.4em 0.8em;
    text-decoration: none;
    color: white;
    font-family: pirulen;
    border: 1px solid white;
    transition: 0.6s;
  }
  .nav-link:hover {
    transform: scale(110%);
    margin-left: 1em;
    margin-right: 1em;
    background-color: white;
    color: #121212;
  }

  .AuthButtons {
    display: flex;
    font-size: 0.2em;
  }

  .AuthButtonContainer {
    display: flex;
    margin-bottom: 0.5em;
  }

  .NavbarLogo {
    margin-left: 1em;
  }
}

@media only screen and (min-width: 74em) {
  .NavbarItems {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nav-link {
    display: flex;
    font-size: 1rem;
    margin-left: 1em;
    margin-right: 1em;
    padding: 0.4em 0.8em 0.4em 0.8em;
    text-decoration: none;
    color: white;
    font-family: pirulen;
    border: 1px solid white;
    transition: 0.6s;
  }
  .nav-link:hover {
    transform: scale(110%);
    margin-left: 1em;
    margin-right: 1em;
    background-color: white;
    color: #121212;
  }

  .AuthButtons {
    display: flex;
    font-size: 0.2em;
  }

  .AuthButtonContainer {
    display: flex;
    margin-bottom: 0.5em;
  }
}
