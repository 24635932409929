

.splitdiv{
    width: 100%;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media only screen and (min-width: 48em){
    .splitdiv{ flex-direction: row;
        align-items: center;}
        .left {
            width: 75%;
            
        }
        .right{
            width: 25%; 
            padding: 0 1.5em;
        }
}

.left {
    width: 100%;
    
}

.right {
    width: 75%;  
}









