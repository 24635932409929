ul {
  list-style: circle;
}

.SidebarIndex {
  max-width: 100%;
  margin: 0;
  box-sizing: border-box;
  color: white;
  font-family: "Times New Roman", Times, serif;
}

/* Fucntionality */

/* .multi-level, */
.item ul,
.nav input[type="checkbox"],
.SidebarIndex input[type="checkbox"] {
  display: none;
}

#menu:checked ~ .multi-level,
.item input:checked ~ ul {
  display: block;
}

.multi-level {
  padding-left: 2em;
  line-height: 3;
}

/* Arrow */

.arrow {
  display: inline-block;
  font-size: 1rem;
  margin-right: 0.3em;
}

/* Styles */

label:hover {
  cursor: pointer;
}

.SidebarIndex {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
}

.item,
a {
  max-width: 100%;
}

#nav-icon {
  font-size: 29px;
  line-height: 50px;
  padding-left: 1em;
  color: white;
}

label {
  width: 100%;
  font-size: 1rem;
}

.nav ul,
.nav li,
label {
  line-height: 50px;
  margin: 0;
  text-decoration: none;
  font-weight: 100;
  width: 100%;
}

.item ul {
  padding: 0 0.25em;
}

.nav li a {
  line-height: 50px;
  margin: 0;
  padding: 0 4em;
  text-decoration: none;
  font-weight: 100;
  width: 100%;
}

.multi-level {
  width: 100%;
  position: -webkit-sticky;
  position: sticky;
}

.item input + .arrow {
  transition: 0.1s;
}

.item input:checked + .arrow {
  transform: rotate(90deg);
  transform: 0.2s;
}

.pageLink {
  margin-left: 2em;
  transition: 2ms;
}

.pageLinkIcon {
  margin-left: 1em;
  text-decoration: none;
  color: white;
}

.linkWrapper:hover {
  background-color: rgb(107, 104, 104);
}

.icon {
  padding-left: 1em;
  height: 3rem;
  width: auto;
}

.indexlink {
  display: flex;
  align-items: center;
  justify-content: center;
}

li {
  display: flex;
  justify-content: flex-start;
  font-size: 1rem;
  margin-bottom: 0.6em;
}

.pageLink {
  text-decoration: none;
  color: white;
}

.soloItem {
  color: white;
  text-decoration: none;
}
