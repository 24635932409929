* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Encode Sans Expanded', sans-serif;
}

/* Note: this is a hack to remove weird whitespace at the side of page - overall styling should be fixed instead */
html {
  background: url('../public/Background.png') center;
}
