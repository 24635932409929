.Footer {
  padding: 0 2em 0 2em;
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #121212;
  margin-top: auto;
  flex-flow: row wrap;
}

.FooterLinks {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  justify-self: center;
  padding: 1em;

  flex-basis: 40%;
  text-align: left;
}

.FooterLogoSocials {
  display: flex;
  flex-direction: column;
  justify-content: center;

  flex-basis: 40%;
}

.FooterItem {
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  padding-bottom: 1em;
  font-family: roboto;
  transition: 0.5s ease-in-out;
  font-weight: 900;
}

.FooterItem:hover {
  transform: scale(110%);
  color: #5baddc;
}

.FooterLogo {
  max-width: 100%;
  padding: 0.5em 0 0.5em 0;
  height: auto;
  transition: 1s;
  margin: auto;
}

.FooterLogo:hover {
  transform: scale(105%);
}

.FooterSocials {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.FooterSocialLink {
  font-size: 3em;
  color: white;
  text-decoration: none;
  padding: 0 0.15em 0 0.15em;
}

/* @media only screen and (min-width: 47em) {
  .FooterItem {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
    padding-bottom: 0.25em;
  }

  .Footer {
    padding: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: #121212;
  }
  .FooterSocialLink {
    font-size: 3em;
    color: white;
    filter: contrast(25%);
    text-decoration: none;
    padding: 0 0.15em 0 0.15em;
    transition: 0.1s ease-in-out;
  }

  .FooterSocialLink:hover {
    filter: contrast(100);
  }
} */
