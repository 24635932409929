.UserManualPage {
  width: 100%;
  display: grid;
  max-width: 90em;
  padding-top: 5em;
  grid-template-areas:
    "MobileIndex MobileIndex MobileIndex"
    "Content Content Content"
    "Content Content Content ";
  grid-template-columns: 33% 33% 33%;
}

@media only screen and (min-width: 49em) {
  .UserManualPage {
    width: 100%;
    display: grid;
    grid-template-areas:
      "Index Content OnThisPage"
      "Index Content OnThisPage"
      "Index Content OnThisPage";
    grid-template-columns: 25% 75%;
  }
}

.UserManualCompoenet {
  border: 2px solid green;
}

.index {
  grid-area: Index;
  height: 100%;
  position: fixed;
}

@media only screen and (min-width: 48em) {
  .MobileIndex {
    display: none;
  }
}
@media only screen and (max-width: 48em) {
  .Index {
    display: none;
  }
}

.Content {
  margin: 2em;
  grid-area: Content;
  height: 100%;
}

.MobileIndex {
  grid-area: MobileIndex;
}

.UserManualBody {
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: #222222;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
}
