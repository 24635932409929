:root {
  --bg: #242526;
  --bg-accent: #484a4d;
  --text-color: dadcel;
  --nav-size: 60px;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

.navbar {
  height: var(--nav-size);

  padding: 0 1rem;
  border-bottom: var(--border);
  text-decoration: none;
}

.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: paleturquoise;
  text-decoration: none;
}

.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  /* background-color: #484a4d; */
  padding: 5px;
  margin: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: filter 300ms;
  color: white;
}

.icon-button:hover {
  filter: brightness(1.2);
}

.dropdown {
  position: absolute;
  top: 8em;
  left: 9em;
  width: 300px;
  transform: translateX(-45%);
  background-color: var(--bg);
  border: var(--border);
  border-radius: var(--border-radius);
  overflow: hidden;
  text-decoration: none;
  transition: height var(--speed) ease;
  z-index: 3;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  margin: 0.5em 1em;
  text-decoration: none;
  color: white;
}

.menu-item:hover {
  background-color: #525357;
}

.icon-right {
  margin-left: auto;
}

.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}

.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-primary-exit {
  position: absolute;
}

.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}
.menu-secondary-enter {
  transform: translateX(110%);
}

.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit {
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

.menu-item .icon-button {
  color: var(--text-color);
  text-decoration: none;
  color: white;
}

.icon {
  height: 3rem;
  width: auto;
}

.indexLabel {
  padding-left: 1.5em;
  text-decoration: none !important;
  color: white;
}

.nav-item {
  width: 100%;
}

.icon-button-sp {
  width: 10.5em;
  color: white;
  font-size: 1em;
  display: flex;
  align-items: center;
  text-decoration: none;
  border: 1px solid white;
  padding: 0.5em 0.5em;
  position: sticky;
  position: -webkit-sticky;
}

.tableOfContents {
  padding-left: 0.5em;
}
