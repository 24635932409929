.onthispage {
  width: 100%;

  background-color: salmon;
  grid-area: onpage;
}

.pagecontent {
  width: 100%;
  min-height: 40vh;
  grid-area: content;
}

.userManualPage {
  display: grid;
  grid-template-areas:
    "title title title"
    "onpage onpage onpage"
    "content content content";
}

@media only screen and (min-width: 47em) {
  .userManualPage {
    grid-template-areas:
      "title title title"
      "content content onpage"
      "content content onpage";
    grid-template-columns: 60% 15%;
  }

  .onthispage {
    border: 2px solid black;
    margin-left: 1em;
  }
}

.title {
  width: 100%;
  grid-area: title;
  font-size: 2rem;
  display: flex;
  flex-direction: column;
}

.onThisPage {
  font-size: 1rem;
  padding: 1em 0;
}

.onThisPageLink {
  font-size: 1rem;
  padding-left: 1em;
  padding-top: 0.5em;
  transition: 1s;
  color: powderblue;
}

.onThisPageLink:hover {
  cursor: pointer;
  font-size: 1.2em;
  border-left: 6px solid powderblue;
}

.onThisPageContainer {
  padding: 1em 0;
}

@media only screen and (min-width: 48em) {
  .onThisPageContainer {
    position: -webkit-sticky;
    position: sticky;
    top: 5em;
  }
}

@media only screen and (min-width: 48em) {
  .onThisPageContainer {
    padding: 0;
    margin-left: 2em;
  }
}

.timeToRead {
  font-size: 0.95rem;
}

.userManualText {
  line-height: 1.6;
  padding: 0.5em 0;
}

.section {
  margin: 1.5em 0;
}

.sectionTitle {
  padding: 1em 0;
}

.sectionImage {
  width: 50px;
  height: auto;
  margin-right: 0.5em;
}

.stickySectionHead {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  position: sticky;
  position: -webkit-sticky;
  top: 5em;
  background-color: #222222;
}

.userManualImage {
  margin: 1em 0;
  width: 100%;
  height: auto;
}

.pageTitleIcon {
  width: 2em;
  height: auto;
  margin-right: 0.5em;
}

.pageTitleIconWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contentList {
  list-style: circle;
}

.sectionSubTitle {
  margin-top: 1.5em;
}

.inArticleLink {
  color: powderblue;
}
